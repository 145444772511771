import { Result } from 'antd';

export { Error };

function Error() {

    return (
        <Result
            status="500"
            title="Error 500"
            subTitle="Sorry, something went wrong."
            style={{ margin: "auto" }}
        />
    );
}
