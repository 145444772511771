import axios from 'axios';
import { history } from '.';
import { UserAuth } from '../../context/AuthContext';

export { useFetchWrapper };

let baseURL = `${process.env.API_URL}`;
if (!baseURL.endsWith('/')) {
    baseURL = baseURL + '/';
}

function useFetchWrapper() {
    const { user } = UserAuth();
    return {
        get: request('GET'),
        post: request('POST'),
        put: request('PUT'),
        patch: request('PATCH'),
        delete: request('DELETE')
    };

    function request(method) {
        return (url, body) => {
            const requestOptions = {
                method,
                headers: authHeader(`${baseURL}${url}`)
            };

            if (body) {
                requestOptions.headers['Content-Type'] = 'application/json';
                requestOptions.body = JSON.stringify(body);
            }

            console.log("requestOptions.body : ", requestOptions.body)
            console.log('`${baseURL}${url}`', `${baseURL}${url}`)

            return axios({
                method: method,
                url: `${baseURL}${url}`,
                data: requestOptions.body,
                headers: requestOptions.headers
            })
                .then(handleResponse)
                .catch(async (error) => {
                    return handleError(error);
                });
        }
    }

    // -----------------------------------------------------
    // helper functions


    function authHeader(url) {
        // return auth header with jwt if user is logged in and request is to the api url
        // const token = user?.accessToken;
        const token = user !== null ? user.accessToken : null;
        const isLoggedIn = !!token;
        const isApiUrl = url.startsWith(baseURL); // process.env.REACT_APP_API_UR

        if (isLoggedIn && isApiUrl) {
            return { Authorization: `Bearer ${token}` };
        } else {
            return {};
        }
    }

    function handleError(response) {
        console.log("handleError(response) : ", response)
        if (response.code === "ERR_NETWORK" || [500].includes(response?.response?.status)) {
            history.navigate('/error');
        }

        return Promise.reject(response.response);
    }

    function handleResponse(response) {
        return response;
    }

}
