import { useContext, createContext, useEffect, useState } from 'react';
import { history } from '../client/_helpers';
import {
    GoogleAuthProvider,
    signInWithEmailAndPassword,
    signInWithPopup,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    sendEmailVerification,
    signOut,
    onAuthStateChanged,
} from 'firebase/auth';
import { auth_ } from '../firebase';

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
    const [user, setUser] = useState(null);

    // -----------------------------

    function redirect() {
        const { from } = history.location.state || { from: { pathname: '/simulation' } };
        history.navigate(from);
    }

    function mapAuthCodeToMessage(authCode) {
        console.log('authCode', authCode)
        // https://firebase.google.com/docs/auth/admin/errors?hl=fr
        switch (authCode) {
            case "auth/invalid-credential":
                return "Email ou mot de passe incorrects";
            case "auth/invalid-login-credentials":
                return "Email ou mot de passe incorrects";
            case "auth/invalid-email":
                return "Email ou mot de passe incorrects";
            case "auth/invalid-password":
                return "Email ou mot de passe incorrects";
            case "auth/email-already-in-use":
                return "L'email renseigné existe déja";
            case "auth/email-not-validated":
                return 'Email non validé';
            default:
                return "Oups somthg went wrong ";
        }
    }

    const logInWithEmailAndPassword = async (email, password) => {
        try {
            const currentUser = await signInWithEmailAndPassword(auth_, email, password);
            if (currentUser.user.emailVerified) {
                setUser(currentUser.user);
                redirect();
            } else {
                return { "type": "authError", "message": mapAuthCodeToMessage('auth/email-not-validated') }
            }
        } catch (error) {
            return { "type": "authError", "message": mapAuthCodeToMessage(error.code) }
        }
    };

    const signInWithGoogle = async () => {
        const googleProvider = new GoogleAuthProvider();
        try {
            await signInWithPopup(auth_, googleProvider); redirect();
        } catch (error) {
            return { "type": "authError", "message": mapAuthCodeToMessage(error.code) }
        };
    }

    const registerWithEmailAndPassword = async (email, password) => {
        try {
            await createUserWithEmailAndPassword(auth_, email, password);
            await sendEmailVerification(auth_.currentUser)
        } catch (error) {
            console.log('error.code ', error, mapAuthCodeToMessage(error.code))
            return { "type": "authError", "message": mapAuthCodeToMessage(error.code) }
        }
    };

    const sendPasswordToResetEmail = async (email) => {
        try {
            await sendPasswordResetEmail(auth_, email);
        } catch (error) {
            return { "type": "authError", "message": mapAuthCodeToMessage(error.code) }
        }
    };

    // -----------------------------



    const logOut = () => {
        signOut(auth_)
        setUser(null);
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth_, (currentUser) => {
            if (currentUser && currentUser.emailVerified) {
                setUser(currentUser);
            } else {
                setUser(null)
            }
        });
        return () => {
            unsubscribe();
        };
    }, []);

    return (
        <AuthContext.Provider value={{ logInWithEmailAndPassword, signInWithGoogle, registerWithEmailAndPassword, sendPasswordToResetEmail, logOut, user }}>
            {children}
        </AuthContext.Provider>
    );
};

export const UserAuth = () => {
    return useContext(AuthContext);
};