import { Suspense, lazy } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { Spin } from 'antd';
import { AuthContextProvider } from './context/AuthContext';

const AppClient = lazy(() => import('./client/AppClient'));

function Loading() {
  return (
    <div style={{ display: 'flex', alignItems: "center", justifyContent: "center", width: "100%", height: "400px" }}>
      <Spin size="large" />
    </div>
  );
}

const App = () => {
  return (
    <Suspense fallback={<Loading />}>
      <AuthContextProvider>
        <RecoilRoot>
          <BrowserRouter>
            <AppClient />
          </BrowserRouter>
        </RecoilRoot>
      </AuthContextProvider>
    </Suspense>
  );
};

export default App;
