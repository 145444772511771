import { atom } from 'recoil';

const simulationAtom = atom({
    key: 'simulation',
    default: {
        // Simple form
        initialForm: true,
        simpleSimulation: true,
        tauxImposition: 30,
        revenuBrutAnnuel: 30000,
        status: "salarie_non_cadre",
        nombrePart: 1,
        loyer: 500,
        augmentationAnuelleLoyer: 0.9,
        charges: 1000,
        prixAchatFAI: 105000,
        fraisNotaire: { "euros": 8000, "perc": "8" },
        fraisAgence: { "euros": 5000, "perc": "5" },
        apport: 20000,
        duree: 25,
        tauxCredit: 4.1,
        tauxAssurance: 0.2,

        prixRevente: 105000,
        anneesRevente: 10,
        appreciationAnuellePrix: 1,

        // Complex form
        vacance: 0,
        travaux: 0,
        mobilier: 0,
        dossierBancaire: 930,
        courtage: 0,
        prelevementSociaux: 17.2,
        chargesLocataire: 50,
        chargesCopro: 1200,
        entretienLogement: 50,
        gestionLocative: 0,
        comptable: 350,
        taxeFonciere: 460,
        includeTravauxResale: true,
        presta: 0,
        pno: 90,
        gli: 180
    },
});

const simulationComparisonAtom = atom({
    key: 'simulationComparison',
    default: {
        "metricsAchat": {
            "details_achat": [
                {
                    "label": "Prix du bien",
                    "value": 100000
                },
                {
                    "label": "Frais de notaire",
                    "value": 8000
                },
                {
                    "label": "Frais d'agence",
                    "value": 5000
                }
            ],
            "achat_total": 113000,
            "emprunt": 93000,
            "mensualite": 503
        },
        "regimeTresorerie": [
            {
                "annees": 1,
                "loyer_annuel": 6000,
                "loyer_annuel_with_charge_loc": 6600,
                "charges_locataire": 600,
                "charges": -1000,
                "assurance": -186,
                "refunded_cap": -2233,
                "interet": -3614,
                "foncier_forfait_impots": -1982,
                "foncier_reel_impots": -567,
                "lmnp_forfait_impots": -1416,
                "lmnp_reel_impots": 0,
                "sci_is_impots": 0,
                "foncier_forfait_tresorerie": -2415,
                "foncier_reel_tresorerie": -999,
                "lmnp_forfait_tresorerie": -1849,
                "lmnp_reel_tresorerie": -433,
                "sci_is_tresorerie": -433,
                "foncier_forfait_rr": -782,
                "foncier_reel_rr": 634,
                "lmnp_forfait_rr": -216,
                "lmnp_reel_rr": 1200,
                "sci_is_rr": 1200
            },
            {
                "annees": 2,
                "loyer_annuel": 6053,
                "loyer_annuel_with_charge_loc": 6653,
                "charges_locataire": 600,
                "charges": -1000,
                "assurance": -186,
                "refunded_cap": -2322,
                "interet": -3524,
                "foncier_forfait_impots": -2000,
                "foncier_reel_impots": -634,
                "lmnp_forfait_impots": -1429,
                "lmnp_reel_impots": 0,
                "sci_is_impots": 0,
                "foncier_forfait_tresorerie": -2380,
                "foncier_reel_tresorerie": -1013,
                "lmnp_forfait_tresorerie": -1808,
                "lmnp_reel_tresorerie": -380,
                "sci_is_tresorerie": -380,
                "foncier_forfait_rr": -657,
                "foncier_reel_rr": 709,
                "lmnp_forfait_rr": -86,
                "lmnp_reel_rr": 1343,
                "sci_is_rr": 1343
            },
            {
                "annees": 3,
                "loyer_annuel": 6108,
                "loyer_annuel_with_charge_loc": 6708,
                "charges_locataire": 600,
                "charges": -1000,
                "assurance": -186,
                "refunded_cap": -2415,
                "interet": -3431,
                "foncier_forfait_impots": -2018,
                "foncier_reel_impots": -704,
                "lmnp_forfait_impots": -1441,
                "lmnp_reel_impots": 0,
                "sci_is_impots": 0,
                "foncier_forfait_tresorerie": -2343,
                "foncier_reel_tresorerie": -1028,
                "lmnp_forfait_tresorerie": -1766,
                "lmnp_reel_tresorerie": -325,
                "sci_is_tresorerie": -325,
                "foncier_forfait_rr": -527,
                "foncier_reel_rr": 787,
                "lmnp_forfait_rr": 49,
                "lmnp_reel_rr": 1491,
                "sci_is_rr": 1491
            },
            {
                "annees": 4,
                "loyer_annuel": 6163,
                "loyer_annuel_with_charge_loc": 6763,
                "charges_locataire": 600,
                "charges": -1000,
                "assurance": -186,
                "refunded_cap": -2512,
                "interet": -3335,
                "foncier_forfait_impots": -2036,
                "foncier_reel_impots": -775,
                "lmnp_forfait_impots": -1454,
                "lmnp_reel_impots": 0,
                "sci_is_impots": 0,
                "foncier_forfait_tresorerie": -2306,
                "foncier_reel_tresorerie": -1045,
                "lmnp_forfait_tresorerie": -1724,
                "lmnp_reel_tresorerie": -270,
                "sci_is_tresorerie": -270,
                "foncier_forfait_rr": -394,
                "foncier_reel_rr": 867,
                "lmnp_forfait_rr": 188,
                "lmnp_reel_rr": 1642,
                "sci_is_rr": 1642
            },
            {
                "annees": 5,
                "loyer_annuel": 6218,
                "loyer_annuel_with_charge_loc": 6818,
                "charges_locataire": 600,
                "charges": -1000,
                "assurance": -186,
                "refunded_cap": -2612,
                "interet": -3234,
                "foncier_forfait_impots": -2054,
                "foncier_reel_impots": -849,
                "lmnp_forfait_impots": -1467,
                "lmnp_reel_impots": 0,
                "sci_is_impots": 0,
                "foncier_forfait_tresorerie": -2269,
                "foncier_reel_tresorerie": -1063,
                "lmnp_forfait_tresorerie": -1682,
                "lmnp_reel_tresorerie": -215,
                "sci_is_tresorerie": -215,
                "foncier_forfait_rr": -257,
                "foncier_reel_rr": 949,
                "lmnp_forfait_rr": 330,
                "lmnp_reel_rr": 1798,
                "sci_is_rr": 1798
            },
            {
                "annees": 6,
                "loyer_annuel": 6274,
                "loyer_annuel_with_charge_loc": 6874,
                "charges_locataire": 600,
                "charges": -1000,
                "assurance": -186,
                "refunded_cap": -2717,
                "interet": -3130,
                "foncier_forfait_impots": -2073,
                "foncier_reel_impots": -924,
                "lmnp_forfait_impots": -1481,
                "lmnp_reel_impots": 0,
                "sci_is_impots": 0,
                "foncier_forfait_tresorerie": -2232,
                "foncier_reel_tresorerie": -1083,
                "lmnp_forfait_tresorerie": -1639,
                "lmnp_reel_tresorerie": -159,
                "sci_is_tresorerie": -159,
                "foncier_forfait_rr": -115,
                "foncier_reel_rr": 1034,
                "lmnp_forfait_rr": 478,
                "lmnp_reel_rr": 1958,
                "sci_is_rr": 1958
            },
            {
                "annees": 7,
                "loyer_annuel": 6331,
                "loyer_annuel_with_charge_loc": 6931,
                "charges_locataire": 600,
                "charges": -1000,
                "assurance": -186,
                "refunded_cap": -2826,
                "interet": -3021,
                "foncier_forfait_impots": -2092,
                "foncier_reel_impots": -1002,
                "lmnp_forfait_impots": -1494,
                "lmnp_reel_impots": 0,
                "sci_is_impots": 0,
                "foncier_forfait_tresorerie": -2193,
                "foncier_reel_tresorerie": -1104,
                "lmnp_forfait_tresorerie": -1596,
                "lmnp_reel_tresorerie": -102,
                "sci_is_tresorerie": -102,
                "foncier_forfait_rr": 32,
                "foncier_reel_rr": 1121,
                "lmnp_forfait_rr": 630,
                "lmnp_reel_rr": 2124,
                "sci_is_rr": 2124
            },
            {
                "annees": 8,
                "loyer_annuel": 6388,
                "loyer_annuel_with_charge_loc": 6988,
                "charges_locataire": 600,
                "charges": -1000,
                "assurance": -186,
                "refunded_cap": -2939,
                "interet": -2908,
                "foncier_forfait_impots": -2111,
                "foncier_reel_impots": -1083,
                "lmnp_forfait_impots": -1508,
                "lmnp_reel_impots": 0,
                "sci_is_impots": 0,
                "foncier_forfait_tresorerie": -2155,
                "foncier_reel_tresorerie": -1127,
                "lmnp_forfait_tresorerie": -1552,
                "lmnp_reel_tresorerie": -45,
                "sci_is_tresorerie": -45,
                "foncier_forfait_rr": 183,
                "foncier_reel_rr": 1211,
                "lmnp_forfait_rr": 786,
                "lmnp_reel_rr": 2294,
                "sci_is_rr": 2294
            },
            {
                "annees": 9,
                "loyer_annuel": 6445,
                "loyer_annuel_with_charge_loc": 7045,
                "charges_locataire": 600,
                "charges": -1000,
                "assurance": -186,
                "refunded_cap": -3056,
                "interet": -2791,
                "foncier_forfait_impots": -2129,
                "foncier_reel_impots": -1165,
                "lmnp_forfait_impots": -1521,
                "lmnp_reel_impots": 0,
                "sci_is_impots": 0,
                "foncier_forfait_tresorerie": -2117,
                "foncier_reel_tresorerie": -1153,
                "lmnp_forfait_tresorerie": -1509,
                "lmnp_reel_tresorerie": 12,
                "sci_is_tresorerie": 12,
                "foncier_forfait_rr": 339,
                "foncier_reel_rr": 1303,
                "lmnp_forfait_rr": 947,
                "lmnp_reel_rr": 2468,
                "sci_is_rr": 2468
            },
            {
                "annees": 10,
                "loyer_annuel": 6503,
                "loyer_annuel_with_charge_loc": 7103,
                "charges_locataire": 600,
                "charges": -1000,
                "assurance": -186,
                "refunded_cap": -3178,
                "interet": -2668,
                "foncier_forfait_impots": -2149,
                "foncier_reel_impots": -1250,
                "lmnp_forfait_impots": -1535,
                "lmnp_reel_impots": 0,
                "sci_is_impots": 0,
                "foncier_forfait_tresorerie": -2078,
                "foncier_reel_tresorerie": -1180,
                "lmnp_forfait_tresorerie": -1464,
                "lmnp_reel_tresorerie": 70,
                "sci_is_tresorerie": 70,
                "foncier_forfait_rr": 500,
                "foncier_reel_rr": 1399,
                "lmnp_forfait_rr": 1114,
                "lmnp_reel_rr": 2649,
                "sci_is_rr": 2649
            },
            {
                "annees": 11,
                "loyer_annuel": 6562,
                "loyer_annuel_with_charge_loc": 7162,
                "charges_locataire": 600,
                "charges": -1000,
                "assurance": -186,
                "refunded_cap": -3306,
                "interet": -2541,
                "foncier_forfait_impots": -2168,
                "foncier_reel_impots": -1338,
                "lmnp_forfait_impots": -1549,
                "lmnp_reel_impots": 0,
                "sci_is_impots": 0,
                "foncier_forfait_tresorerie": -2039,
                "foncier_reel_tresorerie": -1209,
                "lmnp_forfait_tresorerie": -1419,
                "lmnp_reel_tresorerie": 129,
                "sci_is_tresorerie": 129,
                "foncier_forfait_rr": 667,
                "foncier_reel_rr": 1497,
                "lmnp_forfait_rr": 1286,
                "lmnp_reel_rr": 2835,
                "sci_is_rr": 2835
            },
            {
                "annees": 12,
                "loyer_annuel": 6621,
                "loyer_annuel_with_charge_loc": 7221,
                "charges_locataire": 600,
                "charges": -1000,
                "assurance": -186,
                "refunded_cap": -3438,
                "interet": -2409,
                "foncier_forfait_impots": -2188,
                "foncier_reel_impots": -1428,
                "lmnp_forfait_impots": -1563,
                "lmnp_reel_impots": 0,
                "sci_is_impots": 0,
                "foncier_forfait_tresorerie": -1999,
                "foncier_reel_tresorerie": -1240,
                "lmnp_forfait_tresorerie": -1374,
                "lmnp_reel_tresorerie": 188,
                "sci_is_tresorerie": 188,
                "foncier_forfait_rr": 839,
                "foncier_reel_rr": 1598,
                "lmnp_forfait_rr": 1464,
                "lmnp_reel_rr": 3026,
                "sci_is_rr": 3026
            },
            {
                "annees": 13,
                "loyer_annuel": 6681,
                "loyer_annuel_with_charge_loc": 7281,
                "charges_locataire": 600,
                "charges": -1000,
                "assurance": -186,
                "refunded_cap": -3575,
                "interet": -2271,
                "foncier_forfait_impots": -2207,
                "foncier_reel_impots": -1522,
                "lmnp_forfait_impots": -1577,
                "lmnp_reel_impots": 0,
                "sci_is_impots": 0,
                "foncier_forfait_tresorerie": -1959,
                "foncier_reel_tresorerie": -1273,
                "lmnp_forfait_tresorerie": -1328,
                "lmnp_reel_tresorerie": 248,
                "sci_is_tresorerie": 248,
                "foncier_forfait_rr": 1016,
                "foncier_reel_rr": 1702,
                "lmnp_forfait_rr": 1647,
                "lmnp_reel_rr": 3224,
                "sci_is_rr": 3224
            },
            {
                "annees": 14,
                "loyer_annuel": 6741,
                "loyer_annuel_with_charge_loc": 7341,
                "charges_locataire": 600,
                "charges": -1000,
                "assurance": -186,
                "refunded_cap": -3718,
                "interet": -2128,
                "foncier_forfait_impots": -2227,
                "foncier_reel_impots": -1617,
                "lmnp_forfait_impots": -1591,
                "lmnp_reel_impots": 0,
                "sci_is_impots": 0,
                "foncier_forfait_tresorerie": -1919,
                "foncier_reel_tresorerie": -1309,
                "lmnp_forfait_tresorerie": -1283,
                "lmnp_reel_tresorerie": 308,
                "sci_is_tresorerie": 308,
                "foncier_forfait_rr": 1199,
                "foncier_reel_rr": 1809,
                "lmnp_forfait_rr": 1836,
                "lmnp_reel_rr": 3427,
                "sci_is_rr": 3427
            },
            {
                "annees": 15,
                "loyer_annuel": 6801,
                "loyer_annuel_with_charge_loc": 7401,
                "charges_locataire": 600,
                "charges": -1000,
                "assurance": -186,
                "refunded_cap": -3867,
                "interet": -1980,
                "foncier_forfait_impots": -2247,
                "foncier_reel_impots": -1716,
                "lmnp_forfait_impots": -1605,
                "lmnp_reel_impots": 0,
                "sci_is_impots": 0,
                "foncier_forfait_tresorerie": -1879,
                "foncier_reel_tresorerie": -1348,
                "lmnp_forfait_tresorerie": -1237,
                "lmnp_reel_tresorerie": 368,
                "sci_is_tresorerie": 368,
                "foncier_forfait_rr": 1388,
                "foncier_reel_rr": 1919,
                "lmnp_forfait_rr": 2030,
                "lmnp_reel_rr": 3635,
                "sci_is_rr": 3635
            },
            {
                "annees": 16,
                "loyer_annuel": 6863,
                "loyer_annuel_with_charge_loc": 7463,
                "charges_locataire": 600,
                "charges": -1000,
                "assurance": -186,
                "refunded_cap": -4022,
                "interet": -1825,
                "foncier_forfait_impots": -2268,
                "foncier_reel_impots": -1818,
                "lmnp_forfait_impots": -1620,
                "lmnp_reel_impots": 0,
                "sci_is_impots": 0,
                "foncier_forfait_tresorerie": -1837,
                "foncier_reel_tresorerie": -1388,
                "lmnp_forfait_tresorerie": -1189,
                "lmnp_reel_tresorerie": 430,
                "sci_is_tresorerie": 430,
                "foncier_forfait_rr": 1584,
                "foncier_reel_rr": 2034,
                "lmnp_forfait_rr": 2232,
                "lmnp_reel_rr": 3852,
                "sci_is_rr": 3852
            },
            {
                "annees": 17,
                "loyer_annuel": 6924,
                "loyer_annuel_with_charge_loc": 7524,
                "charges_locataire": 600,
                "charges": -1000,
                "assurance": -186,
                "refunded_cap": -4183,
                "interet": -1664,
                "foncier_forfait_impots": -2288,
                "foncier_reel_impots": -1923,
                "lmnp_forfait_impots": -1634,
                "lmnp_reel_impots": 0,
                "sci_is_impots": 0,
                "foncier_forfait_tresorerie": -1796,
                "foncier_reel_tresorerie": -1432,
                "lmnp_forfait_tresorerie": -1143,
                "lmnp_reel_tresorerie": 491,
                "sci_is_tresorerie": 491,
                "foncier_forfait_rr": 1786,
                "foncier_reel_rr": 2151,
                "lmnp_forfait_rr": 2440,
                "lmnp_reel_rr": 4074,
                "sci_is_rr": 4074
            },
            {
                "annees": 18,
                "loyer_annuel": 6987,
                "loyer_annuel_with_charge_loc": 7587,
                "charges_locataire": 600,
                "charges": -1000,
                "assurance": -186,
                "refunded_cap": -4350,
                "interet": -1497,
                "foncier_forfait_impots": -2309,
                "foncier_reel_impots": -2032,
                "lmnp_forfait_impots": -1649,
                "lmnp_reel_impots": 0,
                "sci_is_impots": 0,
                "foncier_forfait_tresorerie": -1754,
                "foncier_reel_tresorerie": -1477,
                "lmnp_forfait_tresorerie": -1095,
                "lmnp_reel_tresorerie": 554,
                "sci_is_tresorerie": 554,
                "foncier_forfait_rr": 1996,
                "foncier_reel_rr": 2273,
                "lmnp_forfait_rr": 2655,
                "lmnp_reel_rr": 4304,
                "sci_is_rr": 4304
            },
            {
                "annees": 19,
                "loyer_annuel": 7050,
                "loyer_annuel_with_charge_loc": 7650,
                "charges_locataire": 600,
                "charges": -1000,
                "assurance": -186,
                "refunded_cap": -4524,
                "interet": -1323,
                "foncier_forfait_impots": -2329,
                "foncier_reel_impots": -2143,
                "lmnp_forfait_impots": -1664,
                "lmnp_reel_impots": 0,
                "sci_is_impots": 0,
                "foncier_forfait_tresorerie": -1712,
                "foncier_reel_tresorerie": -1526,
                "lmnp_forfait_tresorerie": -1046,
                "lmnp_reel_tresorerie": 617,
                "sci_is_tresorerie": 617,
                "foncier_forfait_rr": 2212,
                "foncier_reel_rr": 2398,
                "lmnp_forfait_rr": 2877,
                "lmnp_reel_rr": 4541,
                "sci_is_rr": 4541
            },
            {
                "annees": 20,
                "loyer_annuel": 7113,
                "loyer_annuel_with_charge_loc": 7713,
                "charges_locataire": 600,
                "charges": -1000,
                "assurance": -186,
                "refunded_cap": -4705,
                "interet": -1142,
                "foncier_forfait_impots": -2350,
                "foncier_reel_impots": -2259,
                "lmnp_forfait_impots": -1679,
                "lmnp_reel_impots": 0,
                "sci_is_impots": 0,
                "foncier_forfait_tresorerie": -1670,
                "foncier_reel_tresorerie": -1578,
                "lmnp_forfait_tresorerie": -998,
                "lmnp_reel_tresorerie": 680,
                "sci_is_tresorerie": 680,
                "foncier_forfait_rr": 2435,
                "foncier_reel_rr": 2527,
                "lmnp_forfait_rr": 3106,
                "lmnp_reel_rr": 4785,
                "sci_is_rr": 4785
            },
            {
                "annees": 21,
                "loyer_annuel": 7177,
                "loyer_annuel_with_charge_loc": 7777,
                "charges_locataire": 600,
                "charges": -1000,
                "assurance": -186,
                "refunded_cap": -4893,
                "interet": -954,
                "foncier_forfait_impots": -2371,
                "foncier_reel_impots": -2378,
                "lmnp_forfait_impots": -1694,
                "lmnp_reel_impots": 0,
                "sci_is_impots": 0,
                "foncier_forfait_tresorerie": -1627,
                "foncier_reel_tresorerie": -1633,
                "lmnp_forfait_tresorerie": -949,
                "lmnp_reel_tresorerie": 744,
                "sci_is_tresorerie": 744,
                "foncier_forfait_rr": 2666,
                "foncier_reel_rr": 2660,
                "lmnp_forfait_rr": 3344,
                "lmnp_reel_rr": 5037,
                "sci_is_rr": 5037
            },
            {
                "annees": 22,
                "loyer_annuel": 7242,
                "loyer_annuel_with_charge_loc": 7842,
                "charges_locataire": 600,
                "charges": -1000,
                "assurance": -186,
                "refunded_cap": -5089,
                "interet": -758,
                "foncier_forfait_impots": -2393,
                "foncier_reel_impots": -2501,
                "lmnp_forfait_impots": -1709,
                "lmnp_reel_impots": 0,
                "sci_is_impots": 0,
                "foncier_forfait_tresorerie": -1583,
                "foncier_reel_tresorerie": -1691,
                "lmnp_forfait_tresorerie": -900,
                "lmnp_reel_tresorerie": 809,
                "sci_is_tresorerie": 809,
                "foncier_forfait_rr": 2905,
                "foncier_reel_rr": 2797,
                "lmnp_forfait_rr": 3589,
                "lmnp_reel_rr": 5298,
                "sci_is_rr": 5298
            },
            {
                "annees": 23,
                "loyer_annuel": 7307,
                "loyer_annuel_with_charge_loc": 7907,
                "charges_locataire": 600,
                "charges": -1000,
                "assurance": -186,
                "refunded_cap": -5292,
                "interet": -554,
                "foncier_forfait_impots": -2414,
                "foncier_reel_impots": -2627,
                "lmnp_forfait_impots": -1724,
                "lmnp_reel_impots": -871,
                "sci_is_impots": -747,
                "foncier_forfait_tresorerie": -1540,
                "foncier_reel_tresorerie": -1753,
                "lmnp_forfait_tresorerie": -850,
                "lmnp_reel_tresorerie": 3,
                "sci_is_tresorerie": 127,
                "foncier_forfait_rr": 3152,
                "foncier_reel_rr": 2939,
                "lmnp_forfait_rr": 3842,
                "lmnp_reel_rr": 4696,
                "sci_is_rr": 4819
            },
            {
                "annees": 24,
                "loyer_annuel": 7373,
                "loyer_annuel_with_charge_loc": 7973,
                "charges_locataire": 600,
                "charges": -1000,
                "assurance": -186,
                "refunded_cap": -5504,
                "interet": -343,
                "foncier_forfait_impots": -2436,
                "foncier_reel_impots": -2759,
                "lmnp_forfait_impots": -1740,
                "lmnp_reel_impots": -2025,
                "sci_is_impots": -1738,
                "foncier_forfait_tresorerie": -1496,
                "foncier_reel_tresorerie": -1818,
                "lmnp_forfait_tresorerie": -800,
                "lmnp_reel_tresorerie": -1085,
                "sci_is_tresorerie": -798,
                "foncier_forfait_rr": 3408,
                "foncier_reel_rr": 3086,
                "lmnp_forfait_rr": 4104,
                "lmnp_reel_rr": 3819,
                "sci_is_rr": 4106
            },
            {
                "annees": 25,
                "loyer_annuel": 7439,
                "loyer_annuel_with_charge_loc": 8039,
                "charges_locataire": 600,
                "charges": -1000,
                "assurance": -186,
                "refunded_cap": -5724,
                "interet": -123,
                "foncier_forfait_impots": -2458,
                "foncier_reel_impots": -2894,
                "lmnp_forfait_impots": -1756,
                "lmnp_reel_impots": -2160,
                "sci_is_impots": -1854,
                "foncier_forfait_tresorerie": -1452,
                "foncier_reel_tresorerie": -1887,
                "lmnp_forfait_tresorerie": -749,
                "lmnp_reel_tresorerie": -1154,
                "sci_is_tresorerie": -847,
                "foncier_forfait_rr": 3673,
                "foncier_reel_rr": 3237,
                "lmnp_forfait_rr": 4375,
                "lmnp_reel_rr": 3970,
                "sci_is_rr": 4277
            },
            {
                "annees": 26,
                "loyer_annuel": 7506,
                "loyer_annuel_with_charge_loc": 8106,
                "charges_locataire": 600,
                "charges": -1000,
                "assurance": 0,
                "refunded_cap": 0,
                "interet": 0,
                "foncier_forfait_impots": -2480,
                "foncier_reel_impots": -3071,
                "lmnp_forfait_impots": -1771,
                "lmnp_reel_impots": -2489,
                "sci_is_impots": -2135,
                "foncier_forfait_tresorerie": 4626,
                "foncier_reel_tresorerie": 4035,
                "lmnp_forfait_tresorerie": 5335,
                "lmnp_reel_tresorerie": 4617,
                "sci_is_tresorerie": 4971,
                "foncier_forfait_rr": 4026,
                "foncier_reel_rr": 3435,
                "lmnp_forfait_rr": 4735,
                "lmnp_reel_rr": 4017,
                "sci_is_rr": 4371
            },
            {
                "annees": 27,
                "loyer_annuel": 7573,
                "loyer_annuel_with_charge_loc": 8173,
                "charges_locataire": 600,
                "charges": -1000,
                "assurance": 0,
                "refunded_cap": 0,
                "interet": 0,
                "foncier_forfait_impots": -2502,
                "foncier_reel_impots": -3102,
                "lmnp_forfait_impots": -1787,
                "lmnp_reel_impots": -2520,
                "sci_is_impots": -2163,
                "foncier_forfait_tresorerie": 4671,
                "foncier_reel_tresorerie": 4071,
                "lmnp_forfait_tresorerie": 5386,
                "lmnp_reel_tresorerie": 4653,
                "sci_is_tresorerie": 5010,
                "foncier_forfait_rr": 4071,
                "foncier_reel_rr": 3471,
                "lmnp_forfait_rr": 4786,
                "lmnp_reel_rr": 4053,
                "sci_is_rr": 4410
            },
            {
                "annees": 28,
                "loyer_annuel": 7642,
                "loyer_annuel_with_charge_loc": 8242,
                "charges_locataire": 600,
                "charges": -1000,
                "assurance": 0,
                "refunded_cap": 0,
                "interet": 0,
                "foncier_forfait_impots": -2525,
                "foncier_reel_impots": -3135,
                "lmnp_forfait_impots": -1804,
                "lmnp_reel_impots": -2553,
                "sci_is_impots": -2191,
                "foncier_forfait_tresorerie": 4717,
                "foncier_reel_tresorerie": 4107,
                "lmnp_forfait_tresorerie": 5438,
                "lmnp_reel_tresorerie": 4689,
                "sci_is_tresorerie": 5051,
                "foncier_forfait_rr": 4117,
                "foncier_reel_rr": 3507,
                "lmnp_forfait_rr": 4838,
                "lmnp_reel_rr": 4089,
                "sci_is_rr": 4451
            },
            {
                "annees": 29,
                "loyer_annuel": 7710,
                "loyer_annuel_with_charge_loc": 8310,
                "charges_locataire": 600,
                "charges": -1000,
                "assurance": 0,
                "refunded_cap": 0,
                "interet": 0,
                "foncier_forfait_impots": -2547,
                "foncier_reel_impots": -3167,
                "lmnp_forfait_impots": -1820,
                "lmnp_reel_impots": -2585,
                "sci_is_impots": -2218,
                "foncier_forfait_tresorerie": 4763,
                "foncier_reel_tresorerie": 4143,
                "lmnp_forfait_tresorerie": 5490,
                "lmnp_reel_tresorerie": 4725,
                "sci_is_tresorerie": 5092,
                "foncier_forfait_rr": 4163,
                "foncier_reel_rr": 3543,
                "lmnp_forfait_rr": 4890,
                "lmnp_reel_rr": 4125,
                "sci_is_rr": 4492
            },
            {
                "annees": 30,
                "loyer_annuel": 7780,
                "loyer_annuel_with_charge_loc": 8380,
                "charges_locataire": 600,
                "charges": -1000,
                "assurance": 0,
                "refunded_cap": 0,
                "interet": 0,
                "foncier_forfait_impots": -2571,
                "foncier_reel_impots": -3200,
                "lmnp_forfait_impots": -1836,
                "lmnp_reel_impots": -2618,
                "sci_is_impots": -2246,
                "foncier_forfait_tresorerie": 4809,
                "foncier_reel_tresorerie": 4180,
                "lmnp_forfait_tresorerie": 5544,
                "lmnp_reel_tresorerie": 4762,
                "sci_is_tresorerie": 5134,
                "foncier_forfait_rr": 4209,
                "foncier_reel_rr": 3580,
                "lmnp_forfait_rr": 4944,
                "lmnp_reel_rr": 4162,
                "sci_is_rr": 4534
            }
        ],
        "regimeResale": [
            {
                "annees": 1,
                "fraisNotaire": -8000,
                "fraisAgence": -5000,
                "prix_achat": -100000,
                "loyer_annuel_with_charge_loc_cumsum": 6600,
                "charges_cumsum": -1000,
                "charges_locataire_cumsum": 600,
                "interet_cumsum": -3614,
                "assurance_cumsum": -186,
                "foncier_reel_impots_cumsum": -567,
                "foncier_forfait_impots_cumsum": -1982,
                "lmnp_reel_impots_cumsum": 0,
                "lmnp_forfait_impots_cumsum": -1416,
                "sci_is_impots_cumsum": 0,
                "foncier_forfait_benefices_loc": -182,
                "foncier_reel_benefices_loc": 1233,
                "lmnp_reel_benefices_loc": 1800,
                "lmnp_forfait_benefices_loc": 384,
                "sci_is_benefices_loc": 1800,
                "prix_revente": 101000,
                "pv": -6500,
                "impots_pv": 0,
                "benefices_resale": -12000,
                "foncier_forfait_benefices_total": -12182,
                "foncier_reel_benefices_total": -10767,
                "lmnp_reel_benefices_total": -10200,
                "lmnp_forfait_benefices_total": -11616,
                "sci_is_benefices_total": -10200
            },
            {
                "annees": 2,
                "fraisNotaire": -8000,
                "fraisAgence": -5000,
                "prix_achat": -100000,
                "loyer_annuel_with_charge_loc_cumsum": 13253,
                "charges_cumsum": -2000,
                "charges_locataire_cumsum": 1200,
                "interet_cumsum": -7138,
                "assurance_cumsum": -372,
                "foncier_reel_impots_cumsum": -1201,
                "foncier_forfait_impots_cumsum": -3982,
                "lmnp_reel_impots_cumsum": 0,
                "lmnp_forfait_impots_cumsum": -2845,
                "sci_is_impots_cumsum": 0,
                "foncier_forfait_benefices_loc": -239,
                "foncier_reel_benefices_loc": 2542,
                "lmnp_reel_benefices_loc": 3743,
                "lmnp_forfait_benefices_loc": 898,
                "sci_is_benefices_loc": 3743,
                "prix_revente": 102010,
                "pv": -5490,
                "impots_pv": 0,
                "benefices_resale": -10990,
                "foncier_forfait_benefices_total": -11229,
                "foncier_reel_benefices_total": -8448,
                "lmnp_reel_benefices_total": -7247,
                "lmnp_forfait_benefices_total": -10092,
                "sci_is_benefices_total": -7247
            },
            {
                "annees": 3,
                "fraisNotaire": -8000,
                "fraisAgence": -5000,
                "prix_achat": -100000,
                "loyer_annuel_with_charge_loc_cumsum": 19961,
                "charges_cumsum": -3000,
                "charges_locataire_cumsum": 1800,
                "interet_cumsum": -10569,
                "assurance_cumsum": -558,
                "foncier_reel_impots_cumsum": -1905,
                "foncier_forfait_impots_cumsum": -6000,
                "lmnp_reel_impots_cumsum": 0,
                "lmnp_forfait_impots_cumsum": -4286,
                "sci_is_impots_cumsum": 0,
                "foncier_forfait_benefices_loc": -166,
                "foncier_reel_benefices_loc": 3929,
                "lmnp_reel_benefices_loc": 5834,
                "lmnp_forfait_benefices_loc": 1548,
                "sci_is_benefices_loc": 5834,
                "prix_revente": 103030,
                "pv": -4470,
                "impots_pv": 0,
                "benefices_resale": -9970,
                "foncier_forfait_benefices_total": -10136,
                "foncier_reel_benefices_total": -6041,
                "lmnp_reel_benefices_total": -4136,
                "lmnp_forfait_benefices_total": -8422,
                "sci_is_benefices_total": -4136
            },
            {
                "annees": 4,
                "fraisNotaire": -8000,
                "fraisAgence": -5000,
                "prix_achat": -100000,
                "loyer_annuel_with_charge_loc_cumsum": 26724,
                "charges_cumsum": -4000,
                "charges_locataire_cumsum": 2400,
                "interet_cumsum": -13904,
                "assurance_cumsum": -744,
                "foncier_reel_impots_cumsum": -2680,
                "foncier_forfait_impots_cumsum": -8036,
                "lmnp_reel_impots_cumsum": 0,
                "lmnp_forfait_impots_cumsum": -5740,
                "sci_is_impots_cumsum": 0,
                "foncier_forfait_benefices_loc": 40,
                "foncier_reel_benefices_loc": 5396,
                "lmnp_reel_benefices_loc": 8076,
                "lmnp_forfait_benefices_loc": 2336,
                "sci_is_benefices_loc": 8076,
                "prix_revente": 104060,
                "pv": -3440,
                "impots_pv": 0,
                "benefices_resale": -8940,
                "foncier_forfait_benefices_total": -8900,
                "foncier_reel_benefices_total": -3544,
                "lmnp_reel_benefices_total": -864,
                "lmnp_forfait_benefices_total": -6604,
                "sci_is_benefices_total": -864
            },
            {
                "annees": 5,
                "fraisNotaire": -8000,
                "fraisAgence": -5000,
                "prix_achat": -100000,
                "loyer_annuel_with_charge_loc_cumsum": 33542,
                "charges_cumsum": -5000,
                "charges_locataire_cumsum": 3000,
                "interet_cumsum": -17138,
                "assurance_cumsum": -930,
                "foncier_reel_impots_cumsum": -3529,
                "foncier_forfait_impots_cumsum": -10090,
                "lmnp_reel_impots_cumsum": 0,
                "lmnp_forfait_impots_cumsum": -7207,
                "sci_is_impots_cumsum": 0,
                "foncier_forfait_benefices_loc": 384,
                "foncier_reel_benefices_loc": 6945,
                "lmnp_reel_benefices_loc": 10474,
                "lmnp_forfait_benefices_loc": 3267,
                "sci_is_benefices_loc": 10474,
                "prix_revente": 105101,
                "pv": -2399,
                "impots_pv": 0,
                "benefices_resale": -7899,
                "foncier_forfait_benefices_total": -7515,
                "foncier_reel_benefices_total": -954,
                "lmnp_reel_benefices_total": 2575,
                "lmnp_forfait_benefices_total": -4632,
                "sci_is_benefices_total": 2575
            },
            {
                "annees": 6,
                "fraisNotaire": -8000,
                "fraisAgence": -5000,
                "prix_achat": -100000,
                "loyer_annuel_with_charge_loc_cumsum": 40416,
                "charges_cumsum": -6000,
                "charges_locataire_cumsum": 3600,
                "interet_cumsum": -20268,
                "assurance_cumsum": -1116,
                "foncier_reel_impots_cumsum": -4453,
                "foncier_forfait_impots_cumsum": -12163,
                "lmnp_reel_impots_cumsum": 0,
                "lmnp_forfait_impots_cumsum": -8688,
                "sci_is_impots_cumsum": 0,
                "foncier_forfait_benefices_loc": 869,
                "foncier_reel_benefices_loc": 8579,
                "lmnp_reel_benefices_loc": 13032,
                "lmnp_forfait_benefices_loc": 4344,
                "sci_is_benefices_loc": 13032,
                "prix_revente": 106152,
                "pv": -1348,
                "impots_pv": 0,
                "benefices_resale": -6848,
                "foncier_forfait_benefices_total": -5979,
                "foncier_reel_benefices_total": 1731,
                "lmnp_reel_benefices_total": 6184,
                "lmnp_forfait_benefices_total": -2504,
                "sci_is_benefices_total": 6184
            },
            {
                "annees": 7,
                "fraisNotaire": -8000,
                "fraisAgence": -5000,
                "prix_achat": -100000,
                "loyer_annuel_with_charge_loc_cumsum": 47347,
                "charges_cumsum": -7000,
                "charges_locataire_cumsum": 4200,
                "interet_cumsum": -23289,
                "assurance_cumsum": -1302,
                "foncier_reel_impots_cumsum": -5455,
                "foncier_forfait_impots_cumsum": -14255,
                "lmnp_reel_impots_cumsum": 0,
                "lmnp_forfait_impots_cumsum": -10182,
                "sci_is_impots_cumsum": 0,
                "foncier_forfait_benefices_loc": 1501,
                "foncier_reel_benefices_loc": 10301,
                "lmnp_reel_benefices_loc": 15756,
                "lmnp_forfait_benefices_loc": 5574,
                "sci_is_benefices_loc": 15756,
                "prix_revente": 107213,
                "pv": -287,
                "impots_pv": 0,
                "benefices_resale": -5787,
                "foncier_forfait_benefices_total": -4286,
                "foncier_reel_benefices_total": 4514,
                "lmnp_reel_benefices_total": 9969,
                "lmnp_forfait_benefices_total": -213,
                "sci_is_benefices_total": 9969
            },
            {
                "annees": 8,
                "fraisNotaire": -8000,
                "fraisAgence": -5000,
                "prix_achat": -100000,
                "loyer_annuel_with_charge_loc_cumsum": 54335,
                "charges_cumsum": -8000,
                "charges_locataire_cumsum": 4800,
                "interet_cumsum": -26197,
                "assurance_cumsum": -1488,
                "foncier_reel_impots_cumsum": -6538,
                "foncier_forfait_impots_cumsum": -16366,
                "lmnp_reel_impots_cumsum": 0,
                "lmnp_forfait_impots_cumsum": -11690,
                "sci_is_impots_cumsum": 0,
                "foncier_forfait_benefices_loc": 2284,
                "foncier_reel_benefices_loc": 12112,
                "lmnp_reel_benefices_loc": 18650,
                "lmnp_forfait_benefices_loc": 6960,
                "sci_is_benefices_loc": 18650,
                "prix_revente": 108285,
                "pv": 785,
                "impots_pv": -250,
                "benefices_resale": -4965,
                "foncier_forfait_benefices_total": -2681,
                "foncier_reel_benefices_total": 7147,
                "lmnp_reel_benefices_total": 13685,
                "lmnp_forfait_benefices_total": 1995,
                "sci_is_benefices_total": 13685
            },
            {
                "annees": 9,
                "fraisNotaire": -8000,
                "fraisAgence": -5000,
                "prix_achat": -100000,
                "loyer_annuel_with_charge_loc_cumsum": 61380,
                "charges_cumsum": -9000,
                "charges_locataire_cumsum": 5400,
                "interet_cumsum": -28988,
                "assurance_cumsum": -1674,
                "foncier_reel_impots_cumsum": -7703,
                "foncier_forfait_impots_cumsum": -18495,
                "lmnp_reel_impots_cumsum": 0,
                "lmnp_forfait_impots_cumsum": -13211,
                "sci_is_impots_cumsum": 0,
                "foncier_forfait_benefices_loc": 3223,
                "foncier_reel_benefices_loc": 14015,
                "lmnp_reel_benefices_loc": 21718,
                "lmnp_forfait_benefices_loc": 8507,
                "sci_is_benefices_loc": 21718,
                "prix_revente": 109368,
                "pv": 1868,
                "impots_pv": -569,
                "benefices_resale": -4201,
                "foncier_forfait_benefices_total": -978,
                "foncier_reel_benefices_total": 9814,
                "lmnp_reel_benefices_total": 17517,
                "lmnp_forfait_benefices_total": 4306,
                "sci_is_benefices_total": 17517
            },
            {
                "annees": 10,
                "fraisNotaire": -8000,
                "fraisAgence": -5000,
                "prix_achat": -100000,
                "loyer_annuel_with_charge_loc_cumsum": 68483,
                "charges_cumsum": -10000,
                "charges_locataire_cumsum": 6000,
                "interet_cumsum": -31656,
                "assurance_cumsum": -1860,
                "foncier_reel_impots_cumsum": -8953,
                "foncier_forfait_impots_cumsum": -20644,
                "lmnp_reel_impots_cumsum": 0,
                "lmnp_forfait_impots_cumsum": -14746,
                "sci_is_impots_cumsum": 0,
                "foncier_forfait_benefices_loc": 4323,
                "foncier_reel_benefices_loc": 16014,
                "lmnp_reel_benefices_loc": 24967,
                "lmnp_forfait_benefices_loc": 10221,
                "sci_is_benefices_loc": 24967,
                "prix_revente": 110462,
                "pv": 2962,
                "impots_pv": -861,
                "benefices_resale": -3399,
                "foncier_forfait_benefices_total": 924,
                "foncier_reel_benefices_total": 12615,
                "lmnp_reel_benefices_total": 21568,
                "lmnp_forfait_benefices_total": 6822,
                "sci_is_benefices_total": 21568
            },
            {
                "annees": 11,
                "fraisNotaire": -8000,
                "fraisAgence": -5000,
                "prix_achat": -100000,
                "loyer_annuel_with_charge_loc_cumsum": 75645,
                "charges_cumsum": -11000,
                "charges_locataire_cumsum": 6600,
                "interet_cumsum": -34197,
                "assurance_cumsum": -2046,
                "foncier_reel_impots_cumsum": -10291,
                "foncier_forfait_impots_cumsum": -22812,
                "lmnp_reel_impots_cumsum": 0,
                "lmnp_forfait_impots_cumsum": -16295,
                "sci_is_impots_cumsum": 0,
                "foncier_forfait_benefices_loc": 5590,
                "foncier_reel_benefices_loc": 18111,
                "lmnp_reel_benefices_loc": 28402,
                "lmnp_forfait_benefices_loc": 12107,
                "sci_is_benefices_loc": 28402,
                "prix_revente": 111566,
                "pv": 4066,
                "impots_pv": -1124,
                "benefices_resale": -2558,
                "foncier_forfait_benefices_total": 3032,
                "foncier_reel_benefices_total": 15553,
                "lmnp_reel_benefices_total": 25844,
                "lmnp_forfait_benefices_total": 9549,
                "sci_is_benefices_total": 25844
            },
            {
                "annees": 12,
                "fraisNotaire": -8000,
                "fraisAgence": -5000,
                "prix_achat": -100000,
                "loyer_annuel_with_charge_loc_cumsum": 82866,
                "charges_cumsum": -12000,
                "charges_locataire_cumsum": 7200,
                "interet_cumsum": -36606,
                "assurance_cumsum": -2232,
                "foncier_reel_impots_cumsum": -11719,
                "foncier_forfait_impots_cumsum": -25000,
                "lmnp_reel_impots_cumsum": 0,
                "lmnp_forfait_impots_cumsum": -17858,
                "sci_is_impots_cumsum": 0,
                "foncier_forfait_benefices_loc": 7028,
                "foncier_reel_benefices_loc": 20309,
                "lmnp_reel_benefices_loc": 32028,
                "lmnp_forfait_benefices_loc": 14170,
                "sci_is_benefices_loc": 32028,
                "prix_revente": 112682,
                "pv": 5182,
                "impots_pv": -1359,
                "benefices_resale": -1677,
                "foncier_forfait_benefices_total": 5351,
                "foncier_reel_benefices_total": 18632,
                "lmnp_reel_benefices_total": 30351,
                "lmnp_forfait_benefices_total": 12493,
                "sci_is_benefices_total": 30351
            },
            {
                "annees": 13,
                "fraisNotaire": -8000,
                "fraisAgence": -5000,
                "prix_achat": -100000,
                "loyer_annuel_with_charge_loc_cumsum": 90147,
                "charges_cumsum": -13000,
                "charges_locataire_cumsum": 7800,
                "interet_cumsum": -38877,
                "assurance_cumsum": -2418,
                "foncier_reel_impots_cumsum": -13241,
                "foncier_forfait_impots_cumsum": -27207,
                "lmnp_reel_impots_cumsum": 0,
                "lmnp_forfait_impots_cumsum": -19435,
                "sci_is_impots_cumsum": 0,
                "foncier_forfait_benefices_loc": 8645,
                "foncier_reel_benefices_loc": 22611,
                "lmnp_reel_benefices_loc": 35852,
                "lmnp_forfait_benefices_loc": 16417,
                "sci_is_benefices_loc": 35852,
                "prix_revente": 113809,
                "pv": 6309,
                "impots_pv": -1565,
                "benefices_resale": -756,
                "foncier_forfait_benefices_total": 7889,
                "foncier_reel_benefices_total": 21855,
                "lmnp_reel_benefices_total": 35096,
                "lmnp_forfait_benefices_total": 15661,
                "sci_is_benefices_total": 35096
            },
            {
                "annees": 14,
                "fraisNotaire": -8000,
                "fraisAgence": -5000,
                "prix_achat": -100000,
                "loyer_annuel_with_charge_loc_cumsum": 97488,
                "charges_cumsum": -14000,
                "charges_locataire_cumsum": 8400,
                "interet_cumsum": -41005,
                "assurance_cumsum": -2604,
                "foncier_reel_impots_cumsum": -14858,
                "foncier_forfait_impots_cumsum": -29434,
                "lmnp_reel_impots_cumsum": 0,
                "lmnp_forfait_impots_cumsum": -21026,
                "sci_is_impots_cumsum": 0,
                "foncier_forfait_benefices_loc": 10445,
                "foncier_reel_benefices_loc": 25021,
                "lmnp_reel_benefices_loc": 39879,
                "lmnp_forfait_benefices_loc": 18853,
                "sci_is_benefices_loc": 39879,
                "prix_revente": 114947,
                "pv": 7447,
                "impots_pv": -1741,
                "benefices_resale": 206,
                "foncier_forfait_benefices_total": 10651,
                "foncier_reel_benefices_total": 25227,
                "lmnp_reel_benefices_total": 40085,
                "lmnp_forfait_benefices_total": 19059,
                "sci_is_benefices_total": 40085
            },
            {
                "annees": 15,
                "fraisNotaire": -8000,
                "fraisAgence": -5000,
                "prix_achat": -100000,
                "loyer_annuel_with_charge_loc_cumsum": 104889,
                "charges_cumsum": -15000,
                "charges_locataire_cumsum": 9000,
                "interet_cumsum": -42985,
                "assurance_cumsum": -2790,
                "foncier_reel_impots_cumsum": -16574,
                "foncier_forfait_impots_cumsum": -31681,
                "lmnp_reel_impots_cumsum": 0,
                "lmnp_forfait_impots_cumsum": -22631,
                "sci_is_impots_cumsum": 0,
                "foncier_forfait_benefices_loc": 12433,
                "foncier_reel_benefices_loc": 27540,
                "lmnp_reel_benefices_loc": 44114,
                "lmnp_forfait_benefices_loc": 21483,
                "sci_is_benefices_loc": 44114,
                "prix_revente": 116096,
                "pv": 8596,
                "impots_pv": -1887,
                "benefices_resale": 1209,
                "foncier_forfait_benefices_total": 13642,
                "foncier_reel_benefices_total": 28749,
                "lmnp_reel_benefices_total": 45323,
                "lmnp_forfait_benefices_total": 22692,
                "sci_is_benefices_total": 45323
            },
            {
                "annees": 16,
                "fraisNotaire": -8000,
                "fraisAgence": -5000,
                "prix_achat": -100000,
                "loyer_annuel_with_charge_loc_cumsum": 112352,
                "charges_cumsum": -16000,
                "charges_locataire_cumsum": 9600,
                "interet_cumsum": -44810,
                "assurance_cumsum": -2976,
                "foncier_reel_impots_cumsum": -18392,
                "foncier_forfait_impots_cumsum": -33949,
                "lmnp_reel_impots_cumsum": 0,
                "lmnp_forfait_impots_cumsum": -24251,
                "sci_is_impots_cumsum": 0,
                "foncier_forfait_benefices_loc": 14617,
                "foncier_reel_benefices_loc": 30174,
                "lmnp_reel_benefices_loc": 48566,
                "lmnp_forfait_benefices_loc": 24315,
                "sci_is_benefices_loc": 48566,
                "prix_revente": 117257,
                "pv": 9757,
                "impots_pv": -2003,
                "benefices_resale": 2254,
                "foncier_forfait_benefices_total": 16871,
                "foncier_reel_benefices_total": 32428,
                "lmnp_reel_benefices_total": 50820,
                "lmnp_forfait_benefices_total": 26569,
                "sci_is_benefices_total": 50820
            },
            {
                "annees": 17,
                "fraisNotaire": -8000,
                "fraisAgence": -5000,
                "prix_achat": -100000,
                "loyer_annuel_with_charge_loc_cumsum": 119876,
                "charges_cumsum": -17000,
                "charges_locataire_cumsum": 10200,
                "interet_cumsum": -46474,
                "assurance_cumsum": -3162,
                "foncier_reel_impots_cumsum": -20315,
                "foncier_forfait_impots_cumsum": -36237,
                "lmnp_reel_impots_cumsum": 0,
                "lmnp_forfait_impots_cumsum": -25885,
                "sci_is_impots_cumsum": 0,
                "foncier_forfait_benefices_loc": 17003,
                "foncier_reel_benefices_loc": 32925,
                "lmnp_reel_benefices_loc": 53240,
                "lmnp_forfait_benefices_loc": 27355,
                "sci_is_benefices_loc": 53240,
                "prix_revente": 118430,
                "pv": 10930,
                "impots_pv": -2089,
                "benefices_resale": 3341,
                "foncier_forfait_benefices_total": 20344,
                "foncier_reel_benefices_total": 36266,
                "lmnp_reel_benefices_total": 56581,
                "lmnp_forfait_benefices_total": 30696,
                "sci_is_benefices_total": 56581
            },
            {
                "annees": 18,
                "fraisNotaire": -8000,
                "fraisAgence": -5000,
                "prix_achat": -100000,
                "loyer_annuel_with_charge_loc_cumsum": 127463,
                "charges_cumsum": -18000,
                "charges_locataire_cumsum": 10800,
                "interet_cumsum": -47971,
                "assurance_cumsum": -3348,
                "foncier_reel_impots_cumsum": -22347,
                "foncier_forfait_impots_cumsum": -38546,
                "lmnp_reel_impots_cumsum": 0,
                "lmnp_forfait_impots_cumsum": -27534,
                "sci_is_impots_cumsum": 0,
                "foncier_forfait_benefices_loc": 19598,
                "foncier_reel_benefices_loc": 35797,
                "lmnp_reel_benefices_loc": 58144,
                "lmnp_forfait_benefices_loc": 30610,
                "sci_is_benefices_loc": 58144,
                "prix_revente": 119614,
                "pv": 12114,
                "impots_pv": -2143,
                "benefices_resale": 4471,
                "foncier_forfait_benefices_total": 24069,
                "foncier_reel_benefices_total": 40268,
                "lmnp_reel_benefices_total": 62615,
                "lmnp_forfait_benefices_total": 35081,
                "sci_is_benefices_total": 62615
            },
            {
                "annees": 19,
                "fraisNotaire": -8000,
                "fraisAgence": -5000,
                "prix_achat": -100000,
                "loyer_annuel_with_charge_loc_cumsum": 135113,
                "charges_cumsum": -19000,
                "charges_locataire_cumsum": 11400,
                "interet_cumsum": -49294,
                "assurance_cumsum": -3534,
                "foncier_reel_impots_cumsum": -24490,
                "foncier_forfait_impots_cumsum": -40875,
                "lmnp_reel_impots_cumsum": 0,
                "lmnp_forfait_impots_cumsum": -29198,
                "sci_is_impots_cumsum": 0,
                "foncier_forfait_benefices_loc": 22410,
                "foncier_reel_benefices_loc": 38795,
                "lmnp_reel_benefices_loc": 63285,
                "lmnp_forfait_benefices_loc": 34087,
                "sci_is_benefices_loc": 63285,
                "prix_revente": 120810,
                "pv": 13310,
                "impots_pv": -2165,
                "benefices_resale": 5645,
                "foncier_forfait_benefices_total": 28055,
                "foncier_reel_benefices_total": 44440,
                "lmnp_reel_benefices_total": 68930,
                "lmnp_forfait_benefices_total": 39732,
                "sci_is_benefices_total": 68930
            },
            {
                "annees": 20,
                "fraisNotaire": -8000,
                "fraisAgence": -5000,
                "prix_achat": -100000,
                "loyer_annuel_with_charge_loc_cumsum": 142826,
                "charges_cumsum": -20000,
                "charges_locataire_cumsum": 12000,
                "interet_cumsum": -50436,
                "assurance_cumsum": -3720,
                "foncier_reel_impots_cumsum": -26749,
                "foncier_forfait_impots_cumsum": -43225,
                "lmnp_reel_impots_cumsum": 0,
                "lmnp_forfait_impots_cumsum": -30877,
                "sci_is_impots_cumsum": 0,
                "foncier_forfait_benefices_loc": 25445,
                "foncier_reel_benefices_loc": 41921,
                "lmnp_reel_benefices_loc": 68670,
                "lmnp_forfait_benefices_loc": 37793,
                "sci_is_benefices_loc": 68670,
                "prix_revente": 122019,
                "pv": 14519,
                "impots_pv": -2155,
                "benefices_resale": 6864,
                "foncier_forfait_benefices_total": 32309,
                "foncier_reel_benefices_total": 48785,
                "lmnp_reel_benefices_total": 75534,
                "lmnp_forfait_benefices_total": 44657,
                "sci_is_benefices_total": 75534
            },
            {
                "annees": 21,
                "fraisNotaire": -8000,
                "fraisAgence": -5000,
                "prix_achat": -100000,
                "loyer_annuel_with_charge_loc_cumsum": 150603,
                "charges_cumsum": -21000,
                "charges_locataire_cumsum": 12600,
                "interet_cumsum": -51390,
                "assurance_cumsum": -3906,
                "foncier_reel_impots_cumsum": -29127,
                "foncier_forfait_impots_cumsum": -45596,
                "lmnp_reel_impots_cumsum": 0,
                "lmnp_forfait_impots_cumsum": -32571,
                "sci_is_impots_cumsum": 0,
                "foncier_forfait_benefices_loc": 28711,
                "foncier_reel_benefices_loc": 45180,
                "lmnp_reel_benefices_loc": 74307,
                "lmnp_forfait_benefices_loc": 41736,
                "sci_is_benefices_loc": 74307,
                "prix_revente": 123239,
                "pv": 15739,
                "impots_pv": -2112,
                "benefices_resale": 8127,
                "foncier_forfait_benefices_total": 36838,
                "foncier_reel_benefices_total": 53307,
                "lmnp_reel_benefices_total": 82434,
                "lmnp_forfait_benefices_total": 49863,
                "sci_is_benefices_total": 82434
            },
            {
                "annees": 22,
                "fraisNotaire": -8000,
                "fraisAgence": -5000,
                "prix_achat": -100000,
                "loyer_annuel_with_charge_loc_cumsum": 158445,
                "charges_cumsum": -22000,
                "charges_locataire_cumsum": 13200,
                "interet_cumsum": -52148,
                "assurance_cumsum": -4092,
                "foncier_reel_impots_cumsum": -31628,
                "foncier_forfait_impots_cumsum": -47989,
                "lmnp_reel_impots_cumsum": 0,
                "lmnp_forfait_impots_cumsum": -34280,
                "sci_is_impots_cumsum": 0,
                "foncier_forfait_benefices_loc": 32216,
                "foncier_reel_benefices_loc": 48577,
                "lmnp_reel_benefices_loc": 80205,
                "lmnp_forfait_benefices_loc": 45925,
                "sci_is_benefices_loc": 80205,
                "prix_revente": 124471,
                "pv": 16971,
                "impots_pv": -2343,
                "benefices_resale": 9128,
                "foncier_forfait_benefices_total": 41344,
                "foncier_reel_benefices_total": 57705,
                "lmnp_reel_benefices_total": 89333,
                "lmnp_forfait_benefices_total": 55053,
                "sci_is_benefices_total": 89333
            },
            {
                "annees": 23,
                "fraisNotaire": -8000,
                "fraisAgence": -5000,
                "prix_achat": -100000,
                "loyer_annuel_with_charge_loc_cumsum": 166352,
                "charges_cumsum": -23000,
                "charges_locataire_cumsum": 13800,
                "interet_cumsum": -52702,
                "assurance_cumsum": -4278,
                "foncier_reel_impots_cumsum": -34255,
                "foncier_forfait_impots_cumsum": -50403,
                "lmnp_reel_impots_cumsum": -871,
                "lmnp_forfait_impots_cumsum": -36004,
                "sci_is_impots_cumsum": -747,
                "foncier_forfait_benefices_loc": 35969,
                "foncier_reel_benefices_loc": 52117,
                "lmnp_reel_benefices_loc": 85501,
                "lmnp_forfait_benefices_loc": 50368,
                "sci_is_benefices_loc": 85625,
                "prix_revente": 125716,
                "pv": 18216,
                "impots_pv": -1973,
                "benefices_resale": 10743,
                "foncier_forfait_benefices_total": 46712,
                "foncier_reel_benefices_total": 62860,
                "lmnp_reel_benefices_total": 96244,
                "lmnp_forfait_benefices_total": 61111,
                "sci_is_benefices_total": 96368
            },
            {
                "annees": 24,
                "fraisNotaire": -8000,
                "fraisAgence": -5000,
                "prix_achat": -100000,
                "loyer_annuel_with_charge_loc_cumsum": 174325,
                "charges_cumsum": -24000,
                "charges_locataire_cumsum": 14400,
                "interet_cumsum": -53045,
                "assurance_cumsum": -4464,
                "foncier_reel_impots_cumsum": -37014,
                "foncier_forfait_impots_cumsum": -52839,
                "lmnp_reel_impots_cumsum": -2896,
                "lmnp_forfait_impots_cumsum": -37744,
                "sci_is_impots_cumsum": -2485,
                "foncier_forfait_benefices_loc": 39977,
                "foncier_reel_benefices_loc": 55802,
                "lmnp_reel_benefices_loc": 89920,
                "lmnp_forfait_benefices_loc": 55072,
                "sci_is_benefices_loc": 90331,
                "prix_revente": 126973,
                "pv": 19473,
                "impots_pv": -1808,
                "benefices_resale": 12165,
                "foncier_forfait_benefices_total": 52142,
                "foncier_reel_benefices_total": 67967,
                "lmnp_reel_benefices_total": 102085,
                "lmnp_forfait_benefices_total": 67237,
                "sci_is_benefices_total": 102496
            },
            {
                "annees": 25,
                "fraisNotaire": -8000,
                "fraisAgence": -5000,
                "prix_achat": -100000,
                "loyer_annuel_with_charge_loc_cumsum": 182364,
                "charges_cumsum": -25000,
                "charges_locataire_cumsum": 15000,
                "interet_cumsum": -53168,
                "assurance_cumsum": -4650,
                "foncier_reel_impots_cumsum": -39908,
                "foncier_forfait_impots_cumsum": -55297,
                "lmnp_reel_impots_cumsum": -5056,
                "lmnp_forfait_impots_cumsum": -39500,
                "sci_is_impots_cumsum": -4339,
                "foncier_forfait_benefices_loc": 44249,
                "foncier_reel_benefices_loc": 59638,
                "lmnp_reel_benefices_loc": 94490,
                "lmnp_forfait_benefices_loc": 60046,
                "sci_is_benefices_loc": 95207,
                "prix_revente": 128243,
                "pv": 20743,
                "impots_pv": -1605,
                "benefices_resale": 13638,
                "foncier_forfait_benefices_total": 57887,
                "foncier_reel_benefices_total": 73276,
                "lmnp_reel_benefices_total": 108128,
                "lmnp_forfait_benefices_total": 73684,
                "sci_is_benefices_total": 108845
            },
            {
                "annees": 26,
                "fraisNotaire": -8000,
                "fraisAgence": -5000,
                "prix_achat": -100000,
                "loyer_annuel_with_charge_loc_cumsum": 190470,
                "charges_cumsum": -26000,
                "charges_locataire_cumsum": 15600,
                "interet_cumsum": -53168,
                "assurance_cumsum": -4650,
                "foncier_reel_impots_cumsum": -42979,
                "foncier_forfait_impots_cumsum": -57777,
                "lmnp_reel_impots_cumsum": -7545,
                "lmnp_forfait_impots_cumsum": -41271,
                "sci_is_impots_cumsum": -6474,
                "foncier_forfait_benefices_loc": 48875,
                "foncier_reel_benefices_loc": 63673,
                "lmnp_reel_benefices_loc": 99107,
                "lmnp_forfait_benefices_loc": 65381,
                "sci_is_benefices_loc": 100178,
                "prix_revente": 129525,
                "pv": 22025,
                "impots_pv": -1363,
                "benefices_resale": 15162,
                "foncier_forfait_benefices_total": 64037,
                "foncier_reel_benefices_total": 78835,
                "lmnp_reel_benefices_total": 114269,
                "lmnp_forfait_benefices_total": 80543,
                "sci_is_benefices_total": 115340
            },
            {
                "annees": 27,
                "fraisNotaire": -8000,
                "fraisAgence": -5000,
                "prix_achat": -100000,
                "loyer_annuel_with_charge_loc_cumsum": 198643,
                "charges_cumsum": -27000,
                "charges_locataire_cumsum": 16200,
                "interet_cumsum": -53168,
                "assurance_cumsum": -4650,
                "foncier_reel_impots_cumsum": -46081,
                "foncier_forfait_impots_cumsum": -60279,
                "lmnp_reel_impots_cumsum": -10065,
                "lmnp_forfait_impots_cumsum": -43058,
                "sci_is_impots_cumsum": -8637,
                "foncier_forfait_benefices_loc": 53546,
                "foncier_reel_benefices_loc": 67744,
                "lmnp_reel_benefices_loc": 103760,
                "lmnp_forfait_benefices_loc": 70767,
                "sci_is_benefices_loc": 105188,
                "prix_revente": 130820,
                "pv": 23320,
                "impots_pv": -1082,
                "benefices_resale": 16738,
                "foncier_forfait_benefices_total": 70284,
                "foncier_reel_benefices_total": 84482,
                "lmnp_reel_benefices_total": 120498,
                "lmnp_forfait_benefices_total": 87505,
                "sci_is_benefices_total": 121926
            },
            {
                "annees": 28,
                "fraisNotaire": -8000,
                "fraisAgence": -5000,
                "prix_achat": -100000,
                "loyer_annuel_with_charge_loc_cumsum": 206885,
                "charges_cumsum": -28000,
                "charges_locataire_cumsum": 16800,
                "interet_cumsum": -53168,
                "assurance_cumsum": -4650,
                "foncier_reel_impots_cumsum": -49216,
                "foncier_forfait_impots_cumsum": -62804,
                "lmnp_reel_impots_cumsum": -12618,
                "lmnp_forfait_impots_cumsum": -44862,
                "sci_is_impots_cumsum": -10828,
                "foncier_forfait_benefices_loc": 58263,
                "foncier_reel_benefices_loc": 71851,
                "lmnp_reel_benefices_loc": 108449,
                "lmnp_forfait_benefices_loc": 76205,
                "sci_is_benefices_loc": 110239,
                "prix_revente": 132129,
                "pv": 24629,
                "impots_pv": -762,
                "benefices_resale": 18367,
                "foncier_forfait_benefices_total": 76630,
                "foncier_reel_benefices_total": 90218,
                "lmnp_reel_benefices_total": 126816,
                "lmnp_forfait_benefices_total": 94572,
                "sci_is_benefices_total": 128606
            },
            {
                "annees": 29,
                "fraisNotaire": -8000,
                "fraisAgence": -5000,
                "prix_achat": -100000,
                "loyer_annuel_with_charge_loc_cumsum": 215195,
                "charges_cumsum": -29000,
                "charges_locataire_cumsum": 17400,
                "interet_cumsum": -53168,
                "assurance_cumsum": -4650,
                "foncier_reel_impots_cumsum": -52383,
                "foncier_forfait_impots_cumsum": -65351,
                "lmnp_reel_impots_cumsum": -15203,
                "lmnp_forfait_impots_cumsum": -46682,
                "sci_is_impots_cumsum": -13046,
                "foncier_forfait_benefices_loc": 63026,
                "foncier_reel_benefices_loc": 75994,
                "lmnp_reel_benefices_loc": 113174,
                "lmnp_forfait_benefices_loc": 81695,
                "sci_is_benefices_loc": 115331,
                "prix_revente": 133450,
                "pv": 25950,
                "impots_pv": -401,
                "benefices_resale": 20049,
                "foncier_forfait_benefices_total": 83075,
                "foncier_reel_benefices_total": 96043,
                "lmnp_reel_benefices_total": 133223,
                "lmnp_forfait_benefices_total": 101744,
                "sci_is_benefices_total": 135380
            },
            {
                "annees": 30,
                "fraisNotaire": -8000,
                "fraisAgence": -5000,
                "prix_achat": -100000,
                "loyer_annuel_with_charge_loc_cumsum": 223575,
                "charges_cumsum": -30000,
                "charges_locataire_cumsum": 18000,
                "interet_cumsum": -53168,
                "assurance_cumsum": -4650,
                "foncier_reel_impots_cumsum": -55583,
                "foncier_forfait_impots_cumsum": -67922,
                "lmnp_reel_impots_cumsum": -17821,
                "lmnp_forfait_impots_cumsum": -48518,
                "sci_is_impots_cumsum": -15292,
                "foncier_forfait_benefices_loc": 67835,
                "foncier_reel_benefices_loc": 80174,
                "lmnp_reel_benefices_loc": 117936,
                "lmnp_forfait_benefices_loc": 87239,
                "sci_is_benefices_loc": 120465,
                "prix_revente": 134784,
                "pv": 27284,
                "impots_pv": 0,
                "benefices_resale": 21784,
                "foncier_forfait_benefices_total": 89619,
                "foncier_reel_benefices_total": 101958,
                "lmnp_reel_benefices_total": 139720,
                "lmnp_forfait_benefices_total": 109023,
                "sci_is_benefices_total": 142249
            }
        ]
    }
})

export { simulationAtom, simulationComparisonAtom };
